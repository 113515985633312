<template>
    <div class="d-none d-sm-none d-md-block d-lg-block d-xl-block">
      <header class="header-custom">
        <b-container fluid>
          <b-row>
            <b-col cols="12" md="12" lg="12">
              <b-row>
                <b-col cols="6" md="4" lg="4">
                  <div style="width:100%;height:60px;display:flex;justify-content:flex-start;align-items:center;background-color:;">
                    <h2 style="margin:0;color:#fff">SIMKES Kabupaten Kudus</h2>
                  </div>
                  
                </b-col>
                <b-col cols="6" md="8" lg="8" >
                  <div style="width:100%;height:60px;display:flex;justify-content:flex-end;align-items:center;background-color:;">
                    <h2 style="margin:0;color:#fff">{{ tanggal_berlangsung }}</h2>
                  </div>
                  
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-container>
      </header>
  
      <section style="padding-top: 20px; padding-bottom: 0">
        <b-container fluid>
          <b-row>
            <b-col cols="8" md="8" lg="8">
              <b-row>
                <b-col cols="12" md="12" lg="12">
                  <div style="width:100%;height:430px;display:flex;justify-content:center;align-items:center;background-color:;">
                    <!-- <video :loop="loop" :autoplay="autoplay" muted>
                      <source src="../../assets/display.mp4" type="video/mp4" />
                    </video> -->
                    <img :src="require(`../../assets/icons/kudus.png`)" style="width:50%; height: 380px"/>
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" md="12" lg="12">
                  <hr>
                  <center><h4 style="font-weight:bold">SMS Gateway</h4></center>
                  <h5 style="text-align:center;font-weight:normal">
                    Demi kenyamanan Anda, Kami menyediakan layanan sms pengaduan
                    terhadap layanan kami melalui sms: {{ $store.state.puskesmas.no_telp_pengaduan }} atau email:
                    {{ $store.state.puskesmas.email_pengaduan }}
                  </h5>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="4" md="4" lg="4">
              <b-row>
                <b-col cols="12" md="12" lg="12">
                  <b-card bg-variant="light">
                    <b-row style="height:500px">
                      <b-col cols="12" md="12" lg="12" sm="12" >
                        <center><h1>NO. ANTRIAN</h1></center>
                      </b-col>
                      <b-col cols="12" md="12" lg="12" sm="12">
                        <center><h1 style="font-size:100px">{{ no_antrianya }}</h1></center>
                      </b-col>
                      <b-col cols="12" md="12" lg="12" sm="12">
                        <center><h1>DI LOKET</h1></center>
                      </b-col>
                      <b-col cols="12" md="12" lg="12" sm="12">
                        <center><h1 style="font-size:100px">{{ loketnya }}</h1></center>
                      </b-col>
                      <!-- <b-col cols="4" md="4" lg="4" sm="4" style="height:500px">
                        <center><h3>ANTRIAN</h3></center>
                        <div>
                          <b-card bg-variant="light"
                            ><center>
                              <h5>{{ antrian_loket1 }}</h5>
                            </center></b-card
                          >
                        </div>
                        <div>
                          <b-card bg-variant="light"
                            ><center>
                              <h5>{{ antrian_loket2 }}</h5>
                            </center></b-card
                          >
                        </div>
                        <div>
                          <b-card bg-variant="light"
                            ><center>
                              <h5>{{ antrian_loket3 }}</h5>
                            </center></b-card
                          >
                        </div>
                      </b-col>
                      <b-col cols="4" md="4" lg="4">
                        <div style="padding-top: 10px">
                          <center>
                            <b-icon
                              icon="arrow-right"
                              animation="cylon"
                              font-scale="6"
                              variant="primary"
                            ></b-icon>
                          </center>
                        </div>
                        <div>
                          <center>
                            <b-icon
                              icon="arrow-right"
                              animation="cylon"
                              font-scale="6"
                              variant="primary"
                            ></b-icon>
                          </center>
                        </div>
                        <div>
                          <center>
                            <b-icon
                              icon="arrow-right"
                              animation="cylon"
                              font-scale="6"
                              variant="primary"
                            ></b-icon>
                          </center>
                        </div>
                      </b-col>
                      <b-col cols="4" md="4" lg="4">
                        <center><h3>LOKET</h3></center>
                        <div>
                          <center><h2>LOKET 1</h2></center>
                        </div>
                        <div style="padding-top: 47px">
                          <center><h2>LOKET 2</h2></center>
                        </div>
                        <div style="padding-top: 47px">
                          <center><h2>LOKET 3</h2></center>
                        </div>
                      </b-col> -->
                  </b-row>
                </b-card>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </section>
    <section>
      <b-container fluid style="padding-top: 70px">
        <b-row>
          <b-col cols="3" lg="3" md="3" sm="3" v-for="(item, idx) in master_loket" :key="idx">
            <b-card>
              <b-row>
                <b-col style="background-image: linear-gradient(to right, #9c4098, #d52f65); padding: 5px 0">
                  <center><h1 style="color: white; margin: 0">LOKET {{ item.nama_loket }}</h1></center>
                </b-col>
              </b-row>
              <b-row>
                <b-col style="height: 100px">
                  <center>
                    <h1 style="padding-top: 10px; font-size: 80px">{{ item.antrian_loket }}</h1>
                  </center>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <!-- <b-col cols="4" lg="4" md="4" sm="4">
            <b-card>
              <b-row>
                <b-col style="background-image: linear-gradient(to right, #9c4098, #d52f65); padding: 5px 0">
                  <center><h1 style="color: white; margin: 0">LOKET 2</h1></center>
                </b-col>
              </b-row>
              <b-row>
                <b-col style="height: 100px">
                  <center>
                    <h1 style="padding-top: 10px; font-size: 80px">{{ antrian_loket2 }}</h1>
                  </center>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <b-col cols="4" lg="4" md="4" sm="4">
            <b-card>
              <b-row>
                <b-col style="background-image: linear-gradient(to right, #9c4098, #d52f65); padding: 5px 0">
                  <center><h1 style="color: white; margin: 0">LOKET 3</h1></center>
                </b-col>
              </b-row>
              <b-row>
                <b-col style="height: 100px">
                  <center>
                    <h1 style="padding-top: 10px; font-size: 80px">{{ antrian_loket3 }}</h1>
                  </center>
                </b-col>
              </b-row>
            </b-card>
          </b-col> -->
        </b-row>
      </b-container>
    </section>
  </div>
</template>
<script>
// import axios from "axios";
// import { ipBackend } from "@/ipBackend";
import moment from "moment";
moment.locale("ID");
export default {
  name: "display_loket",
  data() {
    return {
      loop: true,
      autoplay: true,
      master_loket: [],
      jenis_antrian: [],
      antrian_loket1: "0",
      antrian_loket2: "0",
      antrian_loket3: "0",
      antrian_loket4: "0",
      antrian_loket5: "0",
      loket1_id: "",
      loket2_id: "",
      loket3_id: "",
      loket4_id: "",
      loket5_id: "",
      tanggal_berlangsung: "",
      datanya: "",
      no_panggilan: "",
      kode_panggilan: "",
      loketnya: "-",
      no_antrianya: "-",
      room_id: null,
    };
  },
  mounted() {
    this.getDatas();
    // this.getAntrianAktif()
    this.room_id = localStorage.getItem("idp");
    this.$socket.emit("joinRoom", this.room_id);
  },
  computed:{
      logo() {
      return this.$store.state.logo;
    },
  },
  created() {
    this.$socket.on("connect", () => {
      this.$socket.emit("joinRoom", this.room_id)
    });
    this.$socket.off("refresh_layar_loket");
    this.$socket.on("refresh_layar_loket", (data) => {
      console.log(data, "uhuyyyyyyyy");
      this.cekLoket(data.data);
      this.datanya = data.data;
      // console.log(this.datanya, 'itikiwirr');
      this.setAntrian();
    });
  },
  methods: {
    cekLoket(x) {
      let vm = this;
      // console.log(x ,'ini x');
      // console.log(vm.master_loket, 'master loket');
      vm.no_panggilan = `${x.antrian_no}`;
      vm.kode_panggilan = x.initial;
      vm.no_antrianya = `${x.initial}${x.antrian_no}`;
      for (let i = 0; i < vm.master_loket.length; i++) {
        let z = vm.master_loket[i];
        if (x.ms_loket_id == z.ms_loket_id) {
          vm.loketnya = z.nama_loket;
        }
      }
      // this.panggil();
    },
    async getDatas() {
      let vm = this;
      // let tgl = moment(new Date()).format("YYYY-MM-DD");
      vm.tanggal_berlangsung = moment().format("dddd, DD MMMM YYYY");
      let master_loket = await vm.$axios.post("/ms_loket/list", {
        // puskesmas_id: 'a73ffa4d-79ef-4cff-8804-bfae35d391ee'
      });
      // console.log(master_loket, "ini ress");
      vm.master_loket = master_loket.data.data;
      // for (let i = 0; i < master_loket.data.data.length; i++) {
      //   let x = master_loket.data.data[i];
      //   if (x.nama_loket == "1") {
      //     vm.loket1_id = x.ms_loket_id;
      //   } else if (x.nama_loket == "2") {
      //     vm.loket2_id = x.ms_loket_id;
      //   } else if (x.nama_loket == "3") {
      //     vm.loket3_id = x.ms_loket_id;
      //   } else if (x.nama_loket == "4") {
      //     vm.loket4_id = x.ms_loket_id;
      //   } else if (x.nama_loket == "5") {
      //     vm.loket5_id = x.ms_loket_id;
      //   }
      // }
      let tgl = vm.$moment(new Date()).format("YYYY-MM-DD");
      let antrian_aktif = await vm.$axios.post("/antrian/list", {
        status_antrian: 2,
        // puskesmas_id: 'a73ffa4d-79ef-4cff-8804-bfae35d391ee',
        tanggal_antrian: tgl,
      });
      console.log(antrian_aktif, "aktiffffffff");
      for (let i = 0; i < antrian_aktif.data.data.length; i++) {
        let x = antrian_aktif.data.data[i];
        for (let j = 0; j < vm.master_loket.length; j++) {
          let y = vm.master_loket[j];
          if (x.ms_loket_id == y.ms_loket_id) {
            // console.log("masuk 1");
            y.antrian_loket = `${x.initial}${x.nomor_antrian}`;
            vm.no_antrianya = `${x.initial}${x.nomor_antrian}`;
            vm.loketnya = y.nama_loket;
          }
          // else if (x.ms_loket_id == vm.loket2_id) {
          //   console.log("masuk 2");
          //   vm.antrian_loket2 = `${x.initial}${x.nomor_antrian}`;
          //   vm.no_antrianya = `${x.initial}${x.nomor_antrian}`;
          //   vm.loketnya = "2";
          // } else if (x.ms_loket_id == vm.loket3_id) {
          //   console.log("masuk 3");
          //   vm.antrian_loket3 = `${x.initial}${x.nomor_antrian}`;
          //   vm.no_antrianya = `${x.initial}${x.nomor_antrian}`;
          //   vm.loketnya = "3";
          // }
        }
        
      }
      console.log(vm.master_loket, 'ini');
    },
    setAntrian() {
      let vm = this;
      console.log("set antriannnnnnnnnn");
      // if (vm.datanya.ms_loket_id == vm.loket1_id) {
      //   console.log("masuk satuuu");
      //   vm.antrian_loket1 = vm.datanya.no_antrian;
      //   vm.loketnya = "1";
      // } else if (vm.datanya.ms_loket_id == vm.loket2_id) {
      //   vm.antrian_loket2 = vm.datanya.no_antrian;
      //   vm.loketnya = "2";
      // } else if (vm.datanya.ms_loket_id == vm.loket3_id) {
      //   vm.antrian_loket3 = vm.datanya.no_antrian;
      //   vm.loketnya = "3";
      // } else if (vm.datanya.ms_loket_id == vm.loket4_id) {
      //   vm.antrian_loket4 = vm.datanya.no_antrian;
      //   vm.loketnya = "4";
      // } else if (vm.datanya.ms_loket_id == vm.loket5_id) {
      //   vm.antrian_loket5 = vm.datanya.no_antrian;
      //   vm.loketnya = "5";
      // }
        for (let j = 0; j < vm.master_loket.length; j++) {
          let y = vm.master_loket[j];
          if (vm.datanya.ms_loket_id == y.ms_loket_id) {
            // console.log("masuk 1");
            y.antrian_loket = vm.datanya.no_antrian;
            vm.loketnya = y.nama_loket;
          }
          // else if (x.ms_loket_id == vm.loket2_id) {
          //   console.log("masuk 2");
          //   vm.antrian_loket2 = `${x.initial}${x.nomor_antrian}`;
          //   vm.no_antrianya = `${x.initial}${x.nomor_antrian}`;
          //   vm.loketnya = "2";
          // } else if (x.ms_loket_id == vm.loket3_id) {
          //   console.log("masuk 3");
          //   vm.antrian_loket3 = `${x.initial}${x.nomor_antrian}`;
          //   vm.no_antrianya = `${x.initial}${x.nomor_antrian}`;
          //   vm.loketnya = "3";
          // }
        }
    },
    async getAntrianAktif() {
      let vm = this;
      let tgl = vm.$moment(new Date()).format("YYYY-MM-DD");
      let antrian_aktif = await vm.$axios.post("/antrian/list", {
        status_antrian: 2,
        // puskesmas_id: 'a73ffa4d-79ef-4cff-8804-bfae35d391ee',
        tanggal_antrian: tgl,
      });
      console.log(antrian_aktif, "aktiffffffff");
      for (let i = 0; i < antrian_aktif.data.data.length; i++) {
        let x = antrian_aktif.data.data[i];
        if (x.ms_loket_id == vm.loket1_id) {
          console.log("masuk 1");
          vm.antrian_loket1 = `${x.initial}${x.nomor_antrian}`;
          vm.loketnya = "1";
        } else if (x.ms_loket_id == vm.loket2_id) {
          console.log("masuk 2");
          vm.antrian_loket2 = `${x.initial}${x.nomor_antrian}`;
          vm.loketnya = "2";
        } else if (x.ms_loket_id == vm.loket3_id) {
          console.log("masuk 3");
          vm.antrian_loket3 = `${x.initial}${x.nomor_antrian}`;
          vm.loketnya = "3";
        }
      }
    },
    kodenya() {
      let vm = this;
      console.log("kodenya jalan");
      let kode = new Audio(require(`../../assets/sound/sound/${vm.kode_panggilan[0]}.wav`));

      if (vm.kode_panggilan.length == 1) {
        kode.play();
      } else if (vm.kode_panggilan.length == 2) {
        let kode2 = new Audio(require(`../../assets/sound/sound/${vm.kode_panggilan[1]}.wav`));
        kode.play();
        setTimeout(() => {
          kode2.play();
        }, 500);
      }
    },
    panggil() {
      console.log("panggil");
      let nomor_antrian = new Audio(require(`../../assets/sound/sound/nomor_antrian.wav`));
      console.log("tekan kene");
      if (this.no_panggilan.length == 1) {
        let audio = new Audio(require(`../../assets/sound/sound/${this.no_panggilan}.wav`));

        nomor_antrian.play();
        setTimeout(() => {
          this.kodenya();
          setTimeout(() => {
            audio.play();
            this.lokete();
          }, 1000);
        }, 2000);
      } else if (this.no_panggilan.length == 2) {
        if (this.no_panggilan == 10 || this.no_panggilan == 11) {
          let audio = new Audio(require(`../../assets/sound/sound/${this.no_panggilan}.wav`));
          nomor_antrian.play();
          setTimeout(() => {
            this.kodenya();
            setTimeout(() => {
              audio.play();
              this.lokete();
            }, 1000);
          }, 2000);
        } else if (this.no_panggilan[0] == 1) {
          let kesatu = new Audio(require(`../../assets/sound/sound/${this.no_panggilan[1]}.wav`));
          let kedua = new Audio(require(`../../assets/sound/sound/belas.wav`));
          nomor_antrian.play();
          setTimeout(() => {
            this.kodenya();
            setTimeout(() => {
              kesatu.play();
              setTimeout(() => {
                kedua.play();
                this.lokete();
              }, 1000);
            }, 1000);
          }, 2000);
        } else if (this.no_panggilan[0] != 1) {
          if (this.no_panggilan[1] == 0) {
            let kesatu = new Audio(require(`../../assets/sound/sound/${this.no_panggilan[0]}.wav`));
            let kedua = new Audio(require(`../../assets/sound/sound/puluh.wav`));
            nomor_antrian.play();
            setTimeout(() => {
              this.kodenya();
              setTimeout(() => {
                kesatu.play();
                setTimeout(() => {
                  kedua.play();
                  this.lokete();
                }, 1000);
              }, 1000);
            }, 2000);
          } else {
            let kesatu = new Audio(require(`../../assets/sound/sound/${this.no_panggilan[0]}.wav`));
            let kedua = new Audio(require(`../../assets/sound/sound/puluh.wav`));
            let ketiga = new Audio(require(`../../assets/sound/sound/${this.no_panggilan[1]}.wav`));
            nomor_antrian.play();
            setTimeout(() => {
              this.kodenya();
              setTimeout(() => {
                kesatu.play();
                setTimeout(() => {
                  kedua.play();
                  setTimeout(() => {
                    ketiga.play();
                    this.lokete();
                  }, 1000);
                }, 1000);
              }, 1000);
            }, 2000);
          }
        }
      } else if (this.no_panggilan.length == 3) {
        if (this.no_panggilan == 100) {
          let audio = new Audio(require(`../../assets/sound/sound/seratus.wav`));
          nomor_antrian.play();
          setTimeout(() => {
            this.kodenya();
            setTimeout(() => {
              audio.play();
              this.lokete();
            }, 1000);
          }, 2000);
        } else if (this.no_panggilan[0] == 1) {
          if ((this.no_panggilan[1] == 1 && this.no_panggilan[2] == 0) || (this.no_panggilan[1] == 1 && this.no_panggilan[2] == 1)) {
            let kesatu = new Audio(require(`../../assets/sound/sound/seratus.wav`));
            let kedua = new Audio(require(`../../assets/sound/sound/${this.no_panggilan[1]}${this.no_panggilan[2]}.wav`));
            nomor_antrian.play();
            setTimeout(() => {
              this.kodenya();
              setTimeout(() => {
                kesatu.play();
                setTimeout(() => {
                  kedua.play();
                  this.lokete();
                }, 1000);
              }, 1000);
            }, 2000);
          } else if (this.no_panggilan[2] == 0) {
            let kesatu = new Audio(require(`../../assets/sound/sound/seratus.wav`));
            let kedua = new Audio(require(`../../assets/sound/sound/${this.no_panggilan[1]}.wav`));
            let ketiga = new Audio(require(`../../assets/sound/sound/puluh.wav`));
            nomor_antrian.play();
            setTimeout(() => {
              this.kodenya();
              setTimeout(() => {
                kesatu.play();
                setTimeout(() => {
                  kedua.play();
                  setTimeout(() => {
                    ketiga.play();
                    this.lokete();
                  }, 1000);
                }, 1000);
              }, 1000);
            }, 2000);
          } else if (this.no_panggilan[1] == 0) {
            let kesatu = new Audio(require(`../../assets/sound/sound/seratus.wav`));
            let kedua = new Audio(require(`../../assets/sound/sound/${this.no_panggilan[2]}.wav`));
            nomor_antrian.play();
            setTimeout(() => {
              this.kodenya();
              setTimeout(() => {
                kesatu.play();
                setTimeout(() => {
                  kedua.play();
                  this.lokete();
                }, 1000);
              }, 1000);
            }, 2000);
          } else if (this.no_panggilan[1] == 1) {
            let kesatu = new Audio(require(`../../assets/sound/sound/seratus.wav`));
            let kedua = new Audio(require(`../../assets/sound/sound/${this.no_panggilan[2]}.wav`));
            let ketiga = new Audio(require(`../../assets/sound/sound/belas.wav`));
            nomor_antrian.play();
            setTimeout(() => {
              this.kodenya();
              setTimeout(() => {
                kesatu.play();
                setTimeout(() => {
                  kedua.play();
                  setTimeout(() => {
                    ketiga.play();
                    this.lokete();
                  }, 1000);
                }, 1000);
              }, 1000);
            }, 2000);
          } else {
            let kesatu = new Audio(require(`../../assets/sound/sound/seratus.wav`));
            let kedua = new Audio(require(`../../assets/sound/sound/${this.no_panggilan[1]}.wav`));
            let ketiga = new Audio(require(`../../assets/sound/sound/puluh.wav`));
            let keempat = new Audio(require(`../../assets/sound/sound/${this.no_panggilan[2]}.wav`));
            nomor_antrian.play();
            setTimeout(() => {
              this.kodenya();
              setTimeout(() => {
                kesatu.play();
                setTimeout(() => {
                  kedua.play();
                  setTimeout(() => {
                    ketiga.play();
                    setTimeout(() => {
                      keempat.play();
                      this.lokete();
                    }, 1000);
                  }, 1000);
                }, 1000);
              }, 1000);
            }, 2000);
          }
        } else if (this.no_panggilan[0] != 1) {
          if ((this.no_panggilan[1] == 1 && this.no_panggilan[2] == 0) || (this.no_panggilan[1] == 1 && this.no_panggilan[2] == 1)) {
            let kesatu = new Audio(require(`../../assets/sound/sound/${this.no_panggilan[0]}.wav`));
            let kedua = new Audio(require(`../../assets/sound/sound/ratus.wav`));
            let ketiga = new Audio(require(`../../assets/sound/sound/${this.no_panggilan[1]}${this.no_panggilan[2]}.wav`));
            nomor_antrian.play();
            setTimeout(() => {
              this.kodenya();
              setTimeout(() => {
                kesatu.play();
                setTimeout(() => {
                  kedua.play();
                  setTimeout(() => {
                    ketiga.play();
                    this.lokete();
                  }, 1000);
                }, 1000);
              }, 1000);
            }, 2000);
          } else if (this.no_panggilan[1] == 0 && this.no_panggilan[2] == 0) {
            let kesatu = new Audio(require(`../../assets/sound/sound/${this.no_panggilan[0]}.wav`));
            let kedua = new Audio(require(`../../assets/sound/sound/ratus.wav`));
            nomor_antrian.play();
            setTimeout(() => {
              this.kodenya();
              setTimeout(() => {
                kesatu.play();
                setTimeout(() => {
                  kedua.play();
                  this.lokete();
                }, 1000);
              }, 1000);
            }, 2000);
          } else if (this.no_panggilan[1] == 0) {
            let kesatu = new Audio(require(`../../assets/sound/sound/${this.no_panggilan[0]}.wav`));
            let kedua = new Audio(require(`../../assets/sound/sound/ratus.wav`));
            let ketiga = new Audio(require(`../../assets/sound/sound/${this.no_panggilan[2]}.wav`));
            nomor_antrian.play();
            setTimeout(() => {
              this.kodenya();
              setTimeout(() => {
                kesatu.play();
                setTimeout(() => {
                  kedua.play();
                  setTimeout(() => {
                    ketiga.play();
                    this.lokete();
                  }, 1000);
                }, 1000);
              }, 1000);
            }, 2000);
          } else if (this.no_panggilan[2] == 0) {
            let kesatu = new Audio(require(`../../assets/sound/sound/${this.no_panggilan[0]}.wav`));
            let kedua = new Audio(require(`../../assets/sound/sound/ratus.wav`));
            let ketiga = new Audio(require(`../../assets/sound/sound/${this.no_panggilan[1]}.wav`));
            let keempat = new Audio(require(`../../assets/sound/sound/puluh.wav`));
            nomor_antrian.play();
            setTimeout(() => {
              this.kodenya();
              setTimeout(() => {
                kesatu.play();
                setTimeout(() => {
                  kedua.play();
                  setTimeout(() => {
                    ketiga.play();
                    setTimeout(() => {
                      keempat.play();
                      this.lokete();
                    }, 1000);
                  }, 1000);
                }, 1000);
              }, 1000);
            }, 2000);
          } else if (this.no_panggilan[1] == 1) {
            let kesatu = new Audio(require(`../../assets/sound/sound/${this.no_panggilan[0]}.wav`));
            let kedua = new Audio(require(`../../assets/sound/sound/ratus.wav`));
            let ketiga = new Audio(require(`../../assets/sound/sound/${this.no_panggilan[2]}.wav`));
            let keempat = new Audio(require(`../../assets/sound/sound/belas.wav`));
            nomor_antrian.play();
            setTimeout(() => {
              this.kodenya();
              setTimeout(() => {
                kesatu.play();
                setTimeout(() => {
                  kedua.play();
                  setTimeout(() => {
                    ketiga.play();
                    setTimeout(() => {
                      keempat.play();
                      this.lokete();
                    }, 1000);
                  }, 1000);
                }, 1000);
              }, 1000);
            }, 2000);
          } else {
            let kesatu = new Audio(require(`../../assets/sound/sound/${this.no_panggilan[0]}.wav`));
            let kedua = new Audio(require(`../../assets/sound/sound/ratus.wav`));
            let ketiga = new Audio(require(`../../assets/sound/sound/${this.no_panggilan[1]}.wav`));
            let keempat = new Audio(require(`../../assets/sound/sound/puluh.wav`));
            let kelima = new Audio(require(`../../assets/sound/sound/${this.no_panggilan[2]}.wav`));
            nomor_antrian.play();
            setTimeout(() => {
              this.kodenya();
              setTimeout(() => {
                kesatu.play();
                setTimeout(() => {
                  kedua.play();
                  setTimeout(() => {
                    ketiga.play();
                    setTimeout(() => {
                      keempat.play();
                      setTimeout(() => {
                        kelima.play();
                        this.lokete();
                      }, 1000);
                    }, 1000);
                  }, 1000);
                }, 1000);
              }, 1000);
            }, 2000);
          }
        }
      }
      //    else if (this.no_panggilan.length == 4) {
      //     if (this.no_panggilan == 1000) {
      //       let audio = new Audio(
      //         require(`../../assets/sound/sound/seribu.wav`)
      //       );
      //       nomor_antrian.play();
      //       setTimeout(() => {
      //         audio.play();
      //         this.loket();
      //       }, 2000);
      //     } else if (this.no_panggilan[0] == 1) {
      //       if (this.no_panggilan[1] == 0 && this.no_panggilan[2] == 0) {
      //         let kesatu = new Audio(
      //           require(`../../assets/sound/sound/seribu.wav`)
      //         );
      //         let kedua = new Audio(
      //           require(`../../assets/sound/sound/${this.no_panggilan[3]}.wav`)
      //         );
      //         nomor_antrian.play();
      //         setTimeout(() => {
      //           kesatu.play();
      //           setTimeout(() => {
      //             kedua.play();
      //             this.loket();
      //           }, 1000);
      //         }, 2000);
      //       } else if (
      //         (this.no_panggilan[1] == 0 &&
      //           this.no_panggilan[2] == 1 &&
      //           this.no_panggilan[3] == 0) ||
      //         (this.no_panggilan[1] == 0 &&
      //           this.no_panggilan[2] == 1 &&
      //           this.no_panggilan[3] == 1)
      //       ) {
      //         let kesatu = new Audio(
      //           require(`../../assets/sound/sound/seribu.wav`)
      //         );
      //         let kedua = new Audio(
      //           require(`../../assets/sound/sound/${this.no_panggilan[2]}${this.no_panggilan[3]}.wav`)
      //         );
      //         nomor_antrian.play();
      //         setTimeout(() => {
      //           kesatu.play();
      //           setTimeout(() => {
      //             kedua.play();
      //             this.loket();
      //           }, 1000);
      //         }, 2000);
      //       }
      //       let kesatu = new Audio(
      //         require(`../../assets/sound/sound/seribu.wav`)
      //       );
      //       let kedua = new Audio(
      //         require(`../../assets/sound/sound/${this.no_panggilan[1]}.wav`)
      //       );
      //       let ketiga = new Audio(
      //         require(`../../assets/sound/sound/ratus.wav`)
      //       );
      //       let keempat = new Audio(
      //         require(`../../assets/sound/sound/${this.no_panggilan[2]}.wav`)
      //       );
      //       let kelima = new Audio(
      //         require(`../../assets/sound/sound/puluh.wav`)
      //       );
      //       let keenam = new Audio(
      //         require(`../../assets/sound/sound/${this.no_panggilan[3]}.wav`)
      //       );
      //       nomor_antrian.play();
      //       setTimeout(() => {
      //         kesatu.play();
      //         setTimeout(() => {
      //           kedua.play();
      //           setTimeout(() => {
      //             ketiga.play();
      //             setTimeout(() => {
      //               keempat.play();
      //               setTimeout(() => {
      //                 kelima.play();
      //                 setTimeout(() => {
      //                   keenam.play();
      //                   this.loket();
      //                 }, 1000);
      //               }, 1000);
      //             }, 1000);
      //           }, 1000);
      //         }, 1000);
      //       }, 2000);
      //     }
      //   }
    },
    lokete() {
      console.log("sampe loket");
      let loket = new Audio(require(`../../assets/sound/sound/loket.wav`));
      setTimeout(() => {
        loket.play();
        this.nama_loket();
      }, 1000);
    },
    nama_loket() {
      console.log("sampe nama loket");
      let i = this.loketnya;
      let nama_loket = new Audio(require(`../../assets/sound/sound/${i}.wav`));
      setTimeout(() => {
        nama_loket.play();
        this.sedang_manggil = false;
        if (this.on_process == false) {
          this.sedang_proses = false;
        }
      }, 1000);
    },
  },
};
</script>
<style scoped>
video {
  /* position: absolute; */
  top: 0;
  left: 0;
  display: block;
  width: 60vw;
  height: 500px;

  object-fit: fill;
}
/* img {
    top: 0;
    left: 0;
    display: block;
    width: 60vw;
    height: 500px;
    
    object-fit: fill;
  } */
  h5 {
    font-weight: bold;
  }
  .header-custom {
    width: 100%;
    height: 60px;
    /* background-color: #fff; */
    background-image: linear-gradient(to right, #2ecc71, #27ae60);
  }
  .header-custom-mobile {
    height: 100%;
    background-color: whitesmoke;
    /* height: 60px; */
  }
  .logo {
    display: flex;
    /* width: 100%; */
    height: 40px;
    align-items: center;
    justify-content: flex-end;
  }
  .card {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
  }
  .profile,
  .kantor {
    display: flex;
    flex-direction: column;
  }
  .profile span {
    margin-bottom: 2%;
  }
  .kantor span {
    margin-bottom: 2%;
  }
  </style>
  
