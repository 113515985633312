import { render, staticRenderFns } from "./display_antrian_loket.vue?vue&type=template&id=c3962774&scoped=true"
import script from "./display_antrian_loket.vue?vue&type=script&lang=js"
export * from "./display_antrian_loket.vue?vue&type=script&lang=js"
import style0 from "./display_antrian_loket.vue?vue&type=style&index=0&id=c3962774&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c3962774",
  null
  
)

export default component.exports